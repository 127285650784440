import React from 'react';
import useBrowserState, {
  PRINTED_PRODUCTS_BANNER_BROWSER_STATE,
} from '../../hooks/useBrowserState';
import { CertificateList } from './CertificateList';

export const CertificateListLoader: React.FC = () => {
  const { browserState, patchBrowserState } = useBrowserState();

  if (browserState.isLoading) {
    return <></>;
  }

  return (
    <CertificateList
      dismissPrintedProductsBanner={
        browserState?.data?.browserState?.[
          PRINTED_PRODUCTS_BANNER_BROWSER_STATE
        ]
          ? undefined
          : (dismissValue: string) =>
              patchBrowserState({
                [PRINTED_PRODUCTS_BANNER_BROWSER_STATE]: dismissValue,
              })
      }
    />
  );
};
