import React from 'react';
import {
  Card,
  Icon,
  Stack,
  TextStyle,
  DisplayText,
  Button,
} from '@shopify/polaris';
import { CancelSmallMinor } from '@shopify/polaris-icons';
import style from './printedProductsAdvertiseBanner.module.scss';
import { useOpenWindowOnVerisartDotCom } from 'utils/mainWebsiteLogin';

interface PrintedProductsAdvertiseBannerProps {
  dismiss: (dismissValue: string) => void;
}

export const PrintedProductsAdvertiseBanner: React.FC<
  PrintedProductsAdvertiseBannerProps
> = ({ dismiss }) => {
  const openWindowOnVerisartDotCom = useOpenWindowOnVerisartDotCom();
  return (
    <div className={style.getting_started_card}>
      <Card>
        <Card.Section>
          <Stack wrap={false}>
            <Stack.Item fill>
              <Stack alignment="center" wrap={false}>
                <Stack.Item fill>
                  <Stack vertical spacing="tight">
                    <DisplayText size="small">
                      Purchase printed products
                    </DisplayText>
                    <TextStyle variation="subdued">
                      Create a secure link between your physical & digital work
                      with <br />
                      our NFC chips, QR stickers, and holographic certificates.
                    </TextStyle>
                  </Stack>
                </Stack.Item>
                <Stack.Item>
                  <Button
                    external
                    onClick={() =>
                      openWindowOnVerisartDotCom('/pricing#add-on')
                    }
                  >
                    Get products
                  </Button>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  dismiss(new Date().toISOString());
                }}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon source={CancelSmallMinor} />
              </div>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </Card>
    </div>
  );
};
