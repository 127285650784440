import { Button, Card, Link, Checkbox } from '@shopify/polaris';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import t from 'lib/translation';
import { MetafieldFormValues } from '../ProductFormShow';
import { useOpenWindowOnVerisartDotCom } from 'utils/mainWebsiteLogin';

export const QRPanel: React.FC<{ accountHasPurchasedSticker: boolean }> = ({
  accountHasPurchasedSticker,
}) => {
  const openWindowOnVerisartDotCom = useOpenWindowOnVerisartDotCom();
  const methods = useFormContext<MetafieldFormValues>();
  const upSellText = accountHasPurchasedSticker
    ? t('qrStickerPanel.hasPurchasedSticker')
    : t('qrStickerPanel.hasNotPurchasedSticker');
  return (
    <Card>
      <Card.Header title={t('qrStickerPanel.title')} />
      <Card.Section>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <div style={{ display: 'flex' }}>
            <img
              height="56"
              width="56"
              alt="A Verisart QR sticker"
              src="/images/qr_linked_badge.png"
            />
            <p style={{ paddingLeft: '1rem' }}>{upSellText}</p>
          </div>
          <Controller
            name="qr_linkable"
            control={methods.control}
            render={({ field: { value, onChange } }) => {
              if (accountHasPurchasedSticker)
                return (
                  <div
                    style={{
                      textAlign: 'left',
                      paddingTop: '0.5rem',
                      paddingLeft: '4.5rem',
                    }}
                  >
                    <Checkbox
                      label={
                        value === 'true'
                          ? t('qrStickerPanel.qrStickerAttached')
                          : t('qrStickerPanel.qrStickerNotAttached')
                      }
                      checked={value === 'true'}
                      onChange={() => {
                        onChange(value === 'true' ? 'false' : 'true');
                      }}
                    />
                  </div>
                );
              else
                return (
                  <Button
                    primary={true}
                    fullWidth
                    onClick={() =>
                      openWindowOnVerisartDotCom('/pricing#add-on')
                    }
                  >
                    {t('qrStickerPanel.upsell')}
                  </Button>
                );
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              url="https://help.verisart.com/en/articles/5772715-holographic-printed-products"
              external
              removeUnderline
            >
              {t('qrStickerPanel.learnMore')}
            </Link>
          </div>
        </div>
      </Card.Section>
    </Card>
  );
};
