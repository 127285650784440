import { useMutation, useQuery, useQueryClient } from 'react-query';
import API from '../utils/api';

export interface BrowserState {
  [key: string]: string | null;
}
interface AccountBrowserState {
  browserState: BrowserState;
}
const setBrowserState = async (browserState: BrowserState) => {
  await API().put('/account/me/browserState', { browserState });
};

export const GETTING_STARTER_BANNER_BROWSER_STATE = 'gettingStartedBanner';
export const PRINT_UI_BANNER_BROWSER_STATE = 'printUiBanner';
export const PRINTED_PRODUCTS_BANNER_BROWSER_STATE = 'printedProductsBanner';

const getBrowserState = async () => {
  return (await API().get('/account/me/browserState')).data;
};

const useBrowserState = () => {
  const queryClient = useQueryClient();

  const browserState = useQuery<AccountBrowserState>(
    'browserState',
    getBrowserState
  );

  const { mutateAsync } = useMutation(
    async (newBrowserState: BrowserState) => {
      await setBrowserState(newBrowserState);
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries('browserState');
      },
    }
  );
  return {
    browserState,
    patchBrowserState: (newBrowserState: BrowserState) =>
      mutateAsync(newBrowserState),
  };
};

export default useBrowserState;
