import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useGetCertificates, { Certificate } from 'hooks/useGetCertificates';
import {
  Badge,
  Banner,
  BannerStatus,
  Button,
  Card,
  Filters,
  Icon,
  Modal,
  Page,
  ResourceItem,
  ResourceList,
  Stack,
  TextStyle,
  Thumbnail,
  Toast,
} from '@shopify/polaris';
import EmptyStatePage from 'components/EmptyStatePage';
import { determinePageSize, LocalPagination } from 'components/LocalPagination';
import style from './CertificateList.module.scss';
import { useHistory } from 'react-router';
import useCertifyProducts from 'hooks/useCertifyProducts';
import LoadingPage from 'auth/LoadingPage';
import t from 'lib/translation';
import useTurnOffProducts from 'hooks/useTurnOffProducts';
import { SortOptions, SortType } from 'types/Utils';
import { orderBy } from 'lodash';
import { DeleteMinor } from '@shopify/polaris-icons';
import ProductPicker from '../../components/ProductPicker';
import { PrintedProductsAdvertiseBanner } from 'pages/ProductForm/PrintedProductsAdvertiseBanner';
import { getPlatform } from '../../containers/AppBridge';

const CERT_UPDATE_BATCH_SIZE = 5;

interface CertificateListProps {
  dismissPrintedProductsBanner?: (dismissValue: string) => void;
}

export const CertificateList: React.FC<CertificateListProps> = ({
  dismissPrintedProductsBanner,
}) => {
  const { data, refetch } = useGetCertificates();
  const [banner, setBanner] = useState<{
    message: string;
    title: string;
    status: BannerStatus;
    learnMoreUrl: string;
  }>();

  const closeBanner = useCallback(() => setBanner(undefined), []);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [numberOfPages, setNumberOfPages] = useState(0);
  const { push } = useHistory();
  const [deleteError, setDeleteError] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [failToast, setFailToast] = useState(false);
  const [productPickerOpen, setProductPickerOpen] = useState(false);
  const openProductPicker = useCallback(() => {
    closeBanner();
    setProductPickerOpen(true);
  }, [closeBanner]);
  const closeProductPicker = useCallback(() => setProductPickerOpen(false), []);

  const navigateToProductForm = useCallback(
    (id: number) => {
      push(`/product-metadata/${id}`);
    },
    [push]
  );

  const { mutateAsync: certifyProducts } = useCertifyProducts();
  const { turnOffProducts } = useTurnOffProducts();

  const [loadingModal, setLoadingModal] = useState(false);

  const [sortValue, setSortValue] = useState<SortType>('UPDATED_AT');
  const [queryValue, setQueryValue] = useState<string | undefined>(undefined);

  const certificates: Certificate[] = useMemo(() => {
    let tmp: Certificate[] = data?.certificates || [];
    switch (sortValue) {
      case 'NAME_ASC':
        tmp = orderBy(tmp, (c) => c.title.toLowerCase(), 'asc');
        break;
      case 'CREATED_AT':
        tmp = orderBy(tmp, 'createdAt', 'desc');
        break;
      case 'UPDATED_AT':
        tmp = orderBy(tmp, 'updatedAt', 'desc');
        break;
    }

    if (queryValue) {
      tmp = tmp.filter((cert: { title: string }) =>
        cert.title.toLowerCase().includes(queryValue.toLowerCase())
      );
    }
    return tmp;
  }, [data?.certificates, queryValue, sortValue]);

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={[]}
      onQueryChange={(value) => setQueryValue(value)}
      onQueryClear={() => setQueryValue(undefined)}
      onClearAll={() => setQueryValue(undefined)}
    />
  );

  const deleteSingleProduct = useCallback(
    async (productId: number) => {
      try {
        setLoadingModal(true);
        await turnOffProducts([productId]);
        await refetch({ throwOnError: true });
      } catch (error) {
        setDeleteError(true);
      } finally {
        setLoadingModal(false);
      }
    },
    [setLoadingModal, refetch, turnOffProducts]
  );

  const updateProductList = useCallback(
    async (selection: number[]) => {
      setLoadingModal(true);

      let certifiedOneSuccess = false;
      let certifiedOneFail = false;

      const toTurnOff = certificates
        ?.map((item) => item.productId)
        ?.filter((id) => !selection.includes(id));
      if (toTurnOff) {
        for (let i = 0; i < toTurnOff.length; i += CERT_UPDATE_BATCH_SIZE) {
          let currentBatch = toTurnOff.slice(i, i + CERT_UPDATE_BATCH_SIZE);
          await turnOffProducts(currentBatch);
          await refetch({ throwOnError: true });
        }
      }

      const toCertify = selection.filter(
        (id) => !certificates?.map((item) => item.productId)?.includes(id)
      );

      for (let i = 0; i < toCertify.length; i += CERT_UPDATE_BATCH_SIZE) {
        let currentBatch = toCertify.slice(i, i + CERT_UPDATE_BATCH_SIZE);
        const certified = await certifyProducts({
          productIds: currentBatch,
        });
        if (certified.productsAlreadyInDrop.length > 0) {
          certifiedOneFail = true;
        }
        if (certified.productsCertified.length > 0) {
          certifiedOneSuccess = true;
        }
        await refetch({ throwOnError: true });
      }

      if (certifiedOneFail) {
        setFailToast(true);
        setBanner({
          title: t('certificatePage.nftFail.banner.title'),
          message: t('certificatePage.nftFail.banner.message'),
          status: 'critical',
          learnMoreUrl:
            'https://help.verisart.com/en/articles/8257035-how-to-certify-nfts-minted-on-shopify',
        });
      }
      if (certifiedOneSuccess) {
        setSuccessToast(true);
      }
      setLoadingModal(false);
    },
    [
      setLoadingModal,
      setBanner,
      refetch,
      certifyProducts,
      certificates,
      turnOffProducts,
    ]
  );

  useEffect(() => {
    if (certificates) {
      setNumberOfPages(Math.ceil(certificates.length / pageSize));
    } else {
      setNumberOfPages(0);
    }
  }, [certificates]);

  return certificates ? (
    <Page
      title={t('certificatePage.title')}
      primaryAction={
        certificates.length > 0
          ? {
              content: t('certificatePage.actionText'),
              onAction: () => {
                openProductPicker();
              },
            }
          : undefined
      }
    >
      {failToast && (
        <Toast
          content={t('certificatePage.nftFail.toast')}
          onDismiss={() => setFailToast(false)}
        />
      )}
      {successToast && (
        <Toast
          content={t('certificatePage.successToast')}
          onDismiss={() => setSuccessToast(false)}
        />
      )}
      {deleteError && (
        <Banner
          title={t('certificatePage.bannerTitle')}
          onDismiss={() => setDeleteError(false)}
        >
          <p>{t('certificatePage.failedToDelete')}</p>
        </Banner>
      )}
      {dismissPrintedProductsBanner && getPlatform() !== 'Woo' && (
        <div style={{ marginBottom: '1rem' }}>
          <PrintedProductsAdvertiseBanner
            dismiss={dismissPrintedProductsBanner}
          />
        </div>
      )}
      {productPickerOpen && (
        <ProductPicker
          onCancel={() => setProductPickerOpen(false)}
          onSelection={async (items: number[]) => {
            // We are deliberately not awaiting this as we handle the loading state ourselves - see the loading modal
            updateProductList(items);
            closeProductPicker();
          }}
          initialSelectionIds={certificates.map((cert) =>
            cert.productId.toString()
          )}
        />
      )}
      <Modal
        primaryAction={{ loading: true }}
        open={loadingModal}
        onClose={() => {}}
        title={t('certificatePage.loadingModal.title')}
      >
        <Modal.Section>
          <Stack vertical>
            <TextStyle variation="strong">
              {t('certificatePage.loadingModal.heading')}
            </TextStyle>
          </Stack>
        </Modal.Section>
      </Modal>
      {certificates.length <= 0 && !queryValue ? (
        <EmptyStatePage
          heading={t('certificatePage.emptyState.heading')}
          body={t('certificatePage.emptyState.body')}
          linkAction={() => openProductPicker()}
          linkText={t('certificatePage.emptyState.linkText')}
        />
      ) : (
        <Stack vertical>
          {banner && (
            <Banner
              title={banner.title}
              onDismiss={() => setBanner(undefined)}
              status={banner.status}
              secondaryAction={
                banner.learnMoreUrl !== null
                  ? {
                      content: t('certificatePage.learnMore'),
                      url: banner.learnMoreUrl,
                      external: true,
                    }
                  : undefined
              }
            >
              <Stack.Item>{banner.message}</Stack.Item>
            </Banner>
          )}
          <div className={style['spacing-bottom']}>
            <Card>
              <Card.Section>
                <ResourceList
                  sortValue={sortValue}
                  sortOptions={SortOptions}
                  onSortChange={(selected: SortType) => {
                    setSortValue(selected);
                  }}
                  filterControl={filterControl}
                  items={determinePageSize(
                    certificates,
                    currentPage,
                    pageSize,
                    numberOfPages
                  )}
                  renderItem={(item: Certificate) => {
                    return (
                      <ResourceItem
                        onClick={() => navigateToProductForm(item.productId)}
                        id={item.title}
                      >
                        <Stack alignment="center">
                          <Thumbnail
                            source={item.thumbnail!!}
                            alt={item.title}
                          />
                          <Stack.Item fill>
                            <TextStyle variation="strong">
                              {item.title}
                            </TextStyle>
                          </Stack.Item>
                          {item.status === 'NEEDS_REVIEW' && (
                            <Badge status={'critical'}>
                              {t('productActionRequired')}
                            </Badge>
                          )}
                          <Button
                            onClick={() =>
                              navigateToProductForm(item.productId)
                            }
                          >
                            Edit
                          </Button>
                          {!item.printId && (
                            <div
                              onClick={(event) => {
                                event.stopPropagation();
                                deleteSingleProduct(item.productId);
                              }}
                            >
                              <Icon source={DeleteMinor} color="base" />
                            </div>
                          )}
                        </Stack>
                      </ResourceItem>
                    );
                  }}
                />
                <Stack distribution="center">
                  <LocalPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    numberOfPages={numberOfPages}
                  />
                </Stack>
              </Card.Section>
            </Card>
          </div>
        </Stack>
      )}
    </Page>
  ) : (
    <LoadingPage />
  );
};
